import * as React from 'react';
import { Box, Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import { UserTransferPayloadType } from './UserPaymentSteps';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { getAccountName } from '@/helpers/getAccountName';
import { getCurrencySign } from '@/features/fees/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import StyledCheckbox from '@shared/Widgets/StyledCheckbox';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Contact, ContactPaymentTypes } from '@features/contacts/types';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import ConfirmationLine from '@shared/Components/SendMoney/PaymentForm/ConfirmationLine';
import { postTokenTransfer } from '@/features/tokens/slice';
import { isFiat } from '@/helpers/currency';
import { TokenTransferPayload } from '@/features/tokens/types';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';
import { getAccounts } from '@/features/account/slice';

export interface Props {
	userPaymentPayload: UserTransferPayloadType,
	prevStep: () => void
}

const UserPaymentConfirmation = ({ userPaymentPayload, prevStep }: Props) => {

	const { t } = useTranslation('translations');
	const { fetchWithDelay } = useFetchWithDelay();
	const closeModal = useCloseDialogNoEvent();
	const [submitting, setSubmitting] = React.useState(false);
	const [walletId] = React.useState(userPaymentPayload.walletId);
	const [contact, setContact] = useState<Contact>(userPaymentPayload.contact);

	const allContacts = useSelector((state: RootState) => state.contacts.contacts);

	const contactsForPaymentType = allContacts.filter(contact => contact.supportedPayments.includes(ContactPaymentTypes.LOCAL));

	const [saveToContacts, setSaveToContacts] = useState<boolean>(userPaymentPayload.addToContacts);
	const [walletInContacts, setWalletInContacts] = useState<boolean>(false);

	const curr = getCurrencySign(userPaymentPayload.account.currency);
	const isFiatCurr = isFiat(userPaymentPayload.account.currency);
	const beneficiaryName = userPaymentPayload?.contact?.name ||
		userPaymentPayload.user?.companyName ||
		(userPaymentPayload.user?.firstName ? `${userPaymentPayload.user?.firstName} ${userPaymentPayload.user?.lastName}` : null);

	useEffect(() => {
		const _contact = (contactsForPaymentType.find((c) => c.walletId?.toUpperCase() === walletId?.toUpperCase()));
		if (_contact) {
			setWalletInContacts(true);
			setSaveToContacts(false);
			if (_contact.beneficiaryAccountId !== contact?.beneficiaryAccountId) {
				setContact(_contact);
			}
		} else {
			setWalletInContacts(false);
		}
	}, [walletId, contactsForPaymentType, contact?.beneficiaryAccountId]);

	const send = async () => {
		setSubmitting(true);

		try {

			// if (saveToContacts && user) {
			// 	await dispatch(addContact({
			// 		name: user?.companyName ?? `${user.firstName} ${user.lastName}`, walletId,
			// 		business: !!user?.companyName,
			// 		paymentType: ContactPaymentTypes.LOCAL
			// 	}));
			// }
			if (userPaymentPayload.isCustomTokenTransfer) {
				const data: TokenTransferPayload = {
					accountFromId: userPaymentPayload.account.accountId,
					amount: userPaymentPayload.amount,
					tokenId: userPaymentPayload.account.tokenId,
					operation: userPaymentPayload.processProcedure,
					walletId: userPaymentPayload.walletId,
					reference: userPaymentPayload.reference,
					saveToContacts
					// attachments: Object.fromEntries(attachments)
				};

				await postTokenTransfer(data);

			} else {
				await runOperation(userPaymentPayload.processProcedure, {
					accountFromId: `${userPaymentPayload.account.accountId}`,
					accountToId: `${userPaymentPayload.user.accountId}`,
					amount: userPaymentPayload?.feeInAmount ? userPaymentPayload.account.availableBalance : userPaymentPayload.amount,
					details: userPaymentPayload.reference,
					feeInAmount: userPaymentPayload.feeInAmount,
					saveToContacts
				});

				fetchWithDelay(getAccounts(), [3000, 5000, 10000]);
			}




			showSuccess({
				title: 'paymentsubmitted.data.header', text: t('paymentsubmitted.data.sentamount', {
					amount: curr + ' ' + userPaymentPayload?.amount,
					account: userPaymentPayload.isCustomTokenTransfer ? userPaymentPayload.walletId : beneficiaryName,
					'interpolation': {'escapeValue': false}
				}), mui: true
			});

		} catch (e) {
			await showException(e);
		}
		finally {
			closeModal();
			setSubmitting(false);
		}

	};

	const handleConfirm = () => {
		send();
	};

	return (
		<div className='form-content__wrap__bb'>
			<Grid container >
				<Grid item xs={12} >
					<FormHeader title={t('sendmoney.data.confirmheader')} onBack={prevStep} showBack={!!prevStep} />
				</Grid>
				<Grid container item xs={12} alignItems="center" flexWrap='nowrap'>
					<TokenAvatar symbol={userPaymentPayload.account.currency} logoSource={userPaymentPayload.account.currencyLogo ? userPaymentPayload.account.currencyLogo : 'https://abcmob-public.s3.eu-central-1.amazonaws.com/currency_logo/GBP.png'} variant={TokenAvatarSizeEnum.normal} />
					<Typography ml={2} color='text.secondary' sx={{ fontSize: '1.225rem' }}>
						{userPaymentPayload.account.currency} - {t('sendmoneyTranslation.data.cospayuserheader')}
					</Typography>
				</Grid>
				<Grid container item xs={12} mt={1}>
					<Box sx={(theme) => ({
						background: theme.backgroundBoxLevel1,
						borderRadius: theme.mainBorderRadius,
						p: 2,
						width: '100%',
						mb: { xs: 3, md: 0 },
						mt: 1
					})}>
						<Grid container item rowSpacing={1}>
							{userPaymentPayload?.account && <Grid item xs={12}>
								<ConfirmationLine label={t('form.fields.from')}>
									{`${getAccountName(t, userPaymentPayload.account)} `}
								</ConfirmationLine>
							</Grid>}

							{userPaymentPayload?.user && beneficiaryName && <Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.beneficiary')}>
									{beneficiaryName}
								</ConfirmationLine>
							</Grid>}

							{userPaymentPayload?.walletId && <Grid item xs={12}>
								<ConfirmationLine label={t('login.clienId')}>
									{userPaymentPayload.walletId}
								</ConfirmationLine>
							</Grid>}

							{userPaymentPayload?.reference && <Grid item xs={12}>
								<ConfirmationLine label={t('banking.data.reference')}>
									{userPaymentPayload.reference}
								</ConfirmationLine>
							</Grid>}

							{userPaymentPayload?.amount && <Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.transferamount')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={isFiatCurr ? 2 : 5}
										fixedDecimalScale={isFiatCurr ? true : false}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={userPaymentPayload.amount}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid>}
							{userPaymentPayload?.fee ? <Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.transferfee')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={isFiatCurr ? 2 : 8}
										fixedDecimalScale={isFiatCurr ? true : false}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={userPaymentPayload.feeString}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid> : null}
							<Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.totalAmount')}>
									<Typography
										fontSize='1.225rem'
										fontWeight='800'
										lineHeight='1.288rem'
										variant='highlight'
										style={{ display: 'inline' }}
									>
										<NumberFormat
											displayType={'text'}
											decimalScale={isFiatCurr ? 2 : 5}
											fixedDecimalScale={isFiatCurr ? true : false}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={userPaymentPayload.youpay}
											prefix={`${curr} `} />
									</Typography>
								</ConfirmationLine>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} sx={{ pt: '0.5rem' }}>
					<FormControlLabel
						control={<StyledCheckbox
							checked={saveToContacts}
							disabled={walletInContacts}
							onChange={() => {
								setSaveToContacts(!saveToContacts);
							}}
						/>}
						label={<MuiFormText>{t('sendmoneyTranslation.data.addtocontacts')}</MuiFormText>} />
					{walletInContacts && <Typography variant='body2'>{t('sendmoneyTranslation.data.alreadyInContacts')}</Typography>}
				</Grid>
			</Grid>
			<Grid container mt='auto' pt='2rem' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={prevStep}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button
						variant="contained"
						color="primary"
						onClick={handleConfirm}
						sx={{ width: { xs: '100%', md: 'auto' } }}
						disabled={submitting}
					>
						{t('createaccounttab.btn')}
					</Button>
				</Grid>
			</Grid>
		</div>);
};

export default UserPaymentConfirmation;
